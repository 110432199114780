export const DEAL_EVENTS = {
  Invoice_Discounting: 'Deal_Invoice_Discounting',
  Bonds: 'Deal_Bonds-Secured_Invoice_Discounting',
  Gold: 'Deal_GOLD',
  Tap_Reserve: 'Deal_Tap_Reserve',
  Tap_Reserve_3M_plan: 'Tap_Reserve_3M_plan',
  Tap_Reserve_6M_plan: 'Tap_Reserve_6M_plan',
  Tap_Reserve_enter_amount: 'Tap_Reserve_enter_amount',
  Tap_reserve_Client_select: 'Tap_reserve_Client_select',
  Tap_Reserve_Complete_KYC: 'Tap_Reserve_Complete_KYC',
  Tap_Reserve_Generate_Payment_Link: 'Tap_Reserve_Generate_Payment_Link',
};
