export const CLIENT_ONBOARDING_PAGE_TYPE = {
  FIRST_STEP: 'FIRST_STEP',
  ADD_OR_EDIT_CLIENT: 'ADD_OR_EDIT_CLIENT',
  FINAL_STEP: 'FINAL_STEP',
} as const;

export const CLIENT_ONBOARDING_STEPS = {
  [CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP]: {
    stepTitle: 'Add Clients to the Family',
    verifiedKey: 'familyLabel',
    previousStep: CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP,
    nextStepTitle: 'Add Clients to the Family',
    stepDescription: 'Choose the clients you want to add',
    formDescription: 'Choose the clients you want to add',
  },
  [CLIENT_ONBOARDING_PAGE_TYPE.ADD_OR_EDIT_CLIENT]: {
    stepTitle: 'Add Clients to the Family',
    verifiedKey: 'isBankVerified',
    previousStep: CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP,
    nextStepTitle: 'All Done',
    stepDescription: 'Choose the clients you want to add',
    formDescription: 'Choose the clients you want to add',
  },
};

export type ClientOnboardingPageType = keyof typeof CLIENT_ONBOARDING_PAGE_TYPE;
export type ClientOnboardingActions = {
  handleStep: (step: ClientOnboardingPageType) => void;
};

export const SORTABLE_COLUMNS = {
  familyName: 'familyName',
  numberOfClients: 'numberOfClients',
  investedAmount: 'investedAmount',
};

export const FAMILY_EVENTS = {
  SELECT_CLIENT: 'Select_Client',
  Family_Account_Page: 'Family_Account_Page',
  Add_New_Family_Account: 'Add_New_Family_Account',
  Family_Account_Page_View: 'Family_Account_Page_View',
  Add_Family_Member: 'Add_Family_Member',
};
