import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import Input from '../../components/Fields/Input';
import FamilyAccountTable from './FamilyAccountTable';
import { useNavigate } from 'react-router-dom';
import { AddCircle, SearchOutlined } from '@mui/icons-material';
import { debounce } from '../../utils/debounce';
import { useAnalytics } from '../../hooks/useAnalytics';
import FamilyDrawer from './FamilyDrawer/FamilyDrawer';
import { useFamilyAccount } from './useFamilyAccount';
import { useProfile } from '../../context/ProfileContext';
import { FAMILY_EVENTS } from './constants';
import TableSkeleton from '../../components/TableSkeleton';

const FamilyAccount: React.FC = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState('walletBalance');
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { profileData } = useProfile();

  const { track } = useAnalytics();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useFamilyAccount({ searchPhrase: search });

  const isInitialRender = useRef(true);
  const isEventTracked = useRef(false);

  useEffect(() => {
    if (isInitialRender.current && !isEventTracked.current) {
      track({ eventName: FAMILY_EVENTS.Family_Account_Page });
      isInitialRender.current = false;
      isEventTracked.current = true;
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleRowClick = useCallback(
    (row: any) => {
      if (row.url) {
        navigate(row.url);
      }
    },
    [navigate],
  );

  const handleAddNewClient = useCallback(() => {
    setClientToEdit(null);
    setIsEditing(false);
    setDrawerOpen(true);
    track({ eventName: FAMILY_EVENTS.Add_New_Family_Account });
  }, []);

  const handleChangeSort = (e, columnId) => {
    if (columnId === orderBy) {
      setOrder(order === 'desc' ? 'asc' : 'desc');
    }
    setOrderBy(columnId);
  };

  const handleSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const debounceHandleSearch = debounce(handleSearch, 500);

  const familyTableProps = useMemo(
    () => ({
      data: data?.data,
      pageSize,
      pageNo,
      orderBy,
      order,
      handleChangePage,
      handleChangeRowsPerPage,
      handleRowClick,
      handleChangeSort,
      loading: isLoading,
      isEditable: !!profileData?.features?.enableFamilyAccount,
      onEditClick: (row) => {
        setClientToEdit(row);
        setIsEditing(true);
        profileData?.features?.enableFamilyAccount && setDrawerOpen(true);
      },
    }),
    [
      data?.data,
      pageSize,
      pageNo,
      orderBy,
      order,
      handleChangePage,
      handleChangeRowsPerPage,
      handleRowClick,
      handleChangeSort,
      isLoading,
      profileData?.features?.enableFamilyAccount,
    ],
  );

  return (
    <div className="px-4 py-6">
      <div className="mb-6">Family List</div>
      <div className="flex flex-col justify-start gap-3 items-start md:flex-row md:justify-between md:items-center my-6">
        <Input
          prefixIcon={<SearchOutlined />}
          placeholder="Search"
          onChange={debounceHandleSearch}
          className="ml-2 ps-11"
        />
        {profileData?.clientActions?.enableAddFamilyAccount && (
          <div className="flex items-center gap-3">
            <button
              type="button"
              onClick={handleAddNewClient}
              className="bg-green-700 flex items-center gap-2 text-sm disabled:bg-green-700/80 text-white rounded-xl font-bold h-10 w-fit px-4"
            >
              <AddCircle fontSize="small" />
              Create New Family
            </button>
          </div>
        )}
      </div>

      {data?.data ? <FamilyAccountTable {...familyTableProps} /> : <TableSkeleton />}

      <FamilyDrawer
        isOpen={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setClientToEdit(null);
          refetch();
        }}
        clientToEdit={clientToEdit}
        isEditing={isEditing}
      />
    </div>
  );
};

export default FamilyAccount;
