export const API_URL = process.env.REACT_APP_API_BASE_URL ?? 'https://partner-sandbox.tapinvest.in';

export const API_URL_KRAKEN =
  process.env.REACT_APP_KRAKEN_URL ?? 'https://kraken-stage.tapinvest.in';

// Put your endpoint here
export const PATH = {
  // pg-va buffer account availablity
  pgVaBalanceCheck: '/pg-va/v1/ppipl/buffer-available',

  // get clients
  clients: '/client/v2/get-all-client',

  // partners
  partners: '/profile/v1/get-all-partner',
  partnersSummaryData: 'partner2partner/v1/get-master-partner-aggregate',
  getPartnerConfig: 'profile/v1/get-partner-config',
  addSinglePartner: 'partner2partner/v1/upsert-partner-profile',
  partnerVerifyAadhaarInfo: '/partner/v1/aadhaar/add',

  // family accounts
  familyAccounts: 'family-account/v1/get-family-accounts',
  singleFamilyAccount:
    'family-account/v1/get-family-accounts?familyAccountPublicId={{familyAccountPublicId}}',
  addFamilyAccount: 'family-account/v1/upsert-family-accounts',
  // @deprecate
  clientSummaryData: '/client/v2/get-aggregated-client-data',
  clientsStatisticsData: '/client/v2/get-aggregated-client-data',
  client: '/client/v2/client/{{clientId}}',
  clientStatisticsData:
    '/client/v2/get-aggregated-total-client-metric?client-public-id={{clientId}}',
  clientBank: '/client/v1/get-bank-details/{{clientId}}',
  clientKycStatus: '/client/v1/kyc-status?clientId={{clientId}}',
  clientInvestments: '/dashboard/investment/v2/get-all-investments?clientId={{clientId}}',

  // login endpoints
  phoneOTP: '/onboarding/phone/request-otp',
  verifyPhoneOTP: '/onboarding/phone/verify-otp',
  emailOTP: '/onboarding/email/request-otp',
  verifyEmailOTP: '/onboarding/email/verify-otp',

  // kyc endpoints
  partnerPanInfo: '/onboarding/kyc/request-pan-info/{{pan}}/PARTNER',
  savePartnerPanInfoDuringOnboarding: '/onboarding/kyc/confirm-pan-info/{{pan}}', // save PAN during onboarding flow

  aadhaarInfo: '/onboarding/kyc/request-aadhaar-otp/{{aadhaar}}',
  verifyAadhaarInfo: '/onboarding/kyc/verify-aadhaar-otp',
  verifyBankInfo: '/onboarding/kyc/verify-bank',
  aadhaarOCRVerification: 'onboarding/aadhaar-ocr-verification',
  clientAadhaarOCRVerification: 'client/v1/aadhaar/ocr',
  // create client
  createClient: '/client/v1/create',
  clientPanInfo: '/onboarding/kyc/request-pan-info/{{pan}}/CLIENT',
  verifyClientPanInfo: '/client/v1/pan/add',
  clientAadhaarInfo: '/client/v1/aadhaar/verify',
  clientVerifyAadhaarInfo: '/client/v1/aadhaar/add',
  clientVerifyBankInfo: '/client/v1/add-bank-account',
  clientVirtualAccount: '/client/v1/virtual-account?clientId={{clientId}}',

  // create new partner
  getPartnerKycDetails: '/partner2partner/v1/kyc/status?subPartnerPublicId={{subPartnerPublicId}}',
  savePartnerPanInfo:
    '/partner2partner/v1/kyc/confirm-pan-info?pan={{pan}}&subPartnerPublicId={{subPartnerPublicId}}', // save pan during partner onboarding flow
  requestPartnerAadhaarInfo:
    '/partner2partner/v1/kyc/request-aadhaar-otp?aadhaar={{aadhaar}}&subPartnerPublicId={{subPartnerPublicId}}',
  verifyPartnerAadhaarInfo:
    '/partner2partner/v1/kyc/verify-aadhaar-otp?aadhaar={{aadhaar}}&subPartnerPublicId={{subPartnerPublicId}}&otp={{otp}}',
  partnerAadhaarOCRVerification: 'partner2partner/v1/kyc/aadhaar/ocr',
  verifyPartnerBankInfo: `partner2partner/v1/kyc/verify-bank?subPartnerPublicId={{subPartnerPublicId}}&accountNumber={{accountNumber}}&ifsc={{ifsc}}`,

  // get investments
  investments: '/dashboard/investment/v2/partner',
  partnerInvestments: '/dashboard/transaction-views/query',
  walletInvestments: 'dashboard/get-user-ledgers/{{clientId}}',
  subPartnerInvestments: 'partner2partner/v1/get-all-sub-partner-investments',
  investmentsSummary: '/dashboard/investment/v2/latest-partner-aggregates',
  downloadPartnerInvestments: '/dashboard/download-transaction-views',
  downloadWalletInvestments: 'dashboard/download-user-ledgers',

  // download
  csvClientInvestment: '/csv/client-investment/{{clientId}}',
  csvPartnerInvestment: '/csv/partner-investment',
  csvPartnerClient: '/csv/partner-client',
  csvSubPartnerInvestment: '/csv/sub-partner-investment',
  csvSubPartnerInvestmentDownload: '/csv/csv/{{csvId}}',

  deals: '/deals/v1/get-all',
  deal: '/deals/v1/id/{{dealId}}',
  dealInfo: '/deals/v1/deal-info/id/{{dealId}}',
  createInvestment: '/investment/v1/invoice-discounting/create-investment/{{dealId}}/without-otp',
  investmentTerms: '/investment/v1/invoice-discounting/{{dealId}}/investment-terms',
  // fetch partner profile
  getPartnerWithReferral: 'profile/v1/get-partner-with-referral',
  // partner contracts
  getPartnerOnboardingContract: 'contract/get-contract/PARTNER_ONBOARDING',
  partnerSignContract: 'contract/sign-contract',
  getPartnerDealContract: 'contract/document-contract/{{dealId}}/{{financeType}}',
  // tap reserve / p2p
  p2pInvestments: 'p2p/v1/investment-terms',
  p2pKycStatus: 'p2p/v1/kyc-status?clientId={{clientId}}',
  p2pNudges: 'p2p/v1/nudges',
  p2pAadhaar: 'p2p/v1/aadhaar/request-otp',
  p2pOtp: 'p2p/v1/aadhaar/verify-otp',

  // metal api call
  metalTransaction: 'metal/v1/all-transactions',
  partnerMetalCommission: 'metal/v1/partner-commission?amount={{amount}}&product={{metal}}',
  metalLivePrice: 'metal/v1/price',
  historicalMetalData: 'metal/v1/history?from={{from}}&to={{to}}&range={{range}}',
  sellMetal: 'metal/v1/sell',
  downloadMetalInvoice: 'metal/v1/invoice?invoiceId={{invoiceId}}',

  // user payments
  generateUserP2PPaymentLink: 'p2p/v1/generate-link',
  generateUserWalletPaymentLink: 'wallet/v1/generate-link',
  generateUserMetalPaymentLink: 'metal/v1/generate-link',
  createUserPaymentLink: 'v1/payment-link/p2p?key={{key}}',
  createUserPaymentLinkWallet: 'v1/payment-link/wallet?key={{key}}',
  createUserMetalPaymentLink: 'v1/payment-link/metal?key={{key}}',
  CreateP2PUserPayments: 'v1/payment-link/p2p/invest',
  CreateMetalUserPayments: 'v1/payment-link/metal/invest',
  CreateWalletUserPayments: 'v1/payment-link/wallet/add-money',

  // reinvestment
  reinvest:
    'dashboard/investment/v2/reinvestment/optout/{{investmentId}}?client-public-id={{clientId}}',
  // tax report
  taxReportStatus: 'client/v2/taxreport-status?client-public-id={{clientId}}&fy={{year}}',
  taxReportDownload: 'client/v2/taxreport-download?client-public-id={{clientId}}&fy={{year}}',

  // withdraw
  withdrawMoney: '/wallet/v1/wallet-withdrawal-request',

  // bonds
  bondsDetail: 'bonds/v1/deals/{{dealId}}',
  bondsInvestmentTerms: 'bonds/v1/deals/{{dealId}}/investment-term',
  bondsCashflow: 'bonds/v1/deals/{{dealId}}/cash-flow',
  cmrUpload: 'bonds/v1/cmr-upload',
  cmrStatus: 'bonds/v1/cmr/status?client-public-id={{clientId}}',
  bondsPaymentLink: 'bonds/v1/generate-link',
  createUserPaymentLinkBonds: 'v1/payment-link/bond?key={{key}}',
  CreateBondsUserPayments: 'v1/payment-link/bond/invest',
  partnerConsiderationAmount: 'bonds/v1/partner-consideration-amount',

  // bulk upload
  clientBulkUpload: '/csv/bulk-upload/client',
  bulkUploadStatus: '/csv/get-upload-status/{{uploadId}}',
  partnerBulkUpload: '/csv/bulk-upload/partner',
};
