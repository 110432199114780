export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SORTABLE_COLUMNS = {
  dealName: 'dealName',
  investedDate: 'createdAt',
  investedAmount: 'amount',
  payoutOn: 'payoutDate',
  payoutAmount: 'payoutAmount',
  earnedIncome: 'earnedIncome',
};

export const Investment_Events = {
  Download_Invoice: 'Investment_Download_Invoice',
  Investment_Tab_Change: 'Investment_Tab_Change',
};
