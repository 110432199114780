import { useQuery } from "../../services/NetworkManager";
import { NetworkManager } from "../../services";

export function getAllPartners({ queryKey }) {
  const [_, id, pageNo, pageSize, orderBy, order, search] = queryKey[0].split(
    ","
  );

  return NetworkManager.Call({
    method: "post",
    path: NetworkManager.PATH.partners,
    data: {
      currentPage: pageNo,
      pageSize,
      sortBy: orderBy,
      sortDir: order.toUpperCase(),
      searchPhrase: search,
      searchOn: ["firstName", "lastName", "email", "phone", "pan", "panName"],
      filters: [
        {
          field: "masterPartnerId",
          operation: "EQUALS",
          value: id,
        },
        {
          field: "masterPartnerUnlinkedOn",
          operation: "IS_NULL",
        },
      ],
    },
  });
}

export function getPartnersSummary() {
  return NetworkManager.Call({
    method: "get",
    path: NetworkManager.PATH.partnersSummaryData,
  });
}

export function getSubPartnerInvestments({ queryKey }) {
  const [_, pageNo, pageSize, orderBy, order, search] = queryKey[0].split(",");
  return NetworkManager.Call({
    method: "post",
    path: NetworkManager.PATH.subPartnerInvestments,
    data: {
      currentPage: pageNo,
      pageSize,
      // sortBy: orderBy,
      // sortDir: order.toUpperCase(),
      sortBy: "id",
      sortDir: "DESC",
      searchPhrase: search,
      // searchOn: ["firstName", "lastName", "email", "phone", "pan"],
    },
  });
}
export function getSubPartnerInvestmentsCSVDownloadId({ queryKey }) {
  return NetworkManager.Call({
    method: "post",
    path: NetworkManager.PATH.csvSubPartnerInvestment,
    data: { filters: [] },
  });
}
export async function getSubPartnerInvestmentsCSVDownloadLink(
  downloadId: string
) {
  return await NetworkManager.Call({
    method: "get",
    path: NetworkManager.PATH.csvSubPartnerInvestmentDownload.replace(
      "{{csvId}}",
      downloadId
    ),
  });
}

export function usePartners({ payload }) {
  const { id, pageNo, pageSize, orderBy, order, search } = payload;
  const { data, isLoading } = useQuery(
    `${["partners", id, pageNo, pageSize, orderBy, order, search]}`,
    getAllPartners,
    { enabled: !!id }
  );
  return { data, isLoading };
}

export function usePartnersSummary() {
  const { data, isLoading } = useQuery("partnersSummary", getPartnersSummary);
  return { data, isLoading };
}

export function useSubPartnerInvestments({
  pageNo,
  pageSize,
  orderBy,
  order,
  search,
}) {
  const { data, isLoading } = useQuery(
    `${["subPartnerInvestments", pageNo, pageSize, orderBy, order, search]}`,
    getSubPartnerInvestments
  );
  return { data, isLoading };
}

// Add partner API's
export function getPartnerKycDetails({ partnerId, setPartnerDetails }) {
  return NetworkManager.Call({
    method: "get",
    path: NetworkManager.PATH.getPartnerKycDetails.replace(
      "{{subPartnerPublicId}}",
      partnerId
    ),
  }).then((res) => {
    setPartnerDetails((prev) => ({ ...prev, kycStatus: res?.data }));
  });
}
