export const CLIENT_ONBOARDING_PAGE_TYPE = {
  FIRST_STEP: 'FIRST_STEP',
  IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION',
  PAN_VERIFICATION: 'PAN_VERIFICATION',
  AADHAAR_VERIFICATION: 'AADHAAR_VERIFICATION',
  BANK_VERIFICATION: 'BANK_VERIFICATION',
  FINAL_STEP: 'FINAL_STEP',
} as const;

export const CLIENT_ONBOARDING_STEPS = {
  [CLIENT_ONBOARDING_PAGE_TYPE.IDENTITY_VERIFICATION]: {
    stepTitle: 'Identity Verification',
    verifiedKey: 'isIdentityVerified',
    nextStep: CLIENT_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION,
    previousStep: CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP,
    nextStepTitle: 'Pan Details',
    stepDescription: 'Your clients email & phone number',
    formDescription: 'Enter phone number and email address of the client you wish to add.',
  },
  [CLIENT_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION]: {
    stepTitle: 'PAN Verification',
    verifiedKey: 'isPanVerified',
    nextStep: CLIENT_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION,
    previousStep: CLIENT_ONBOARDING_PAGE_TYPE.IDENTITY_VERIFICATION,
    nextStepTitle: 'Aadhaar',
    stepDescription: 'This is needed as per Govt. of India’s guidelines.',
    formDescription:
      "Enter the client's PAN Number that is linked to their Aadhaar to successfully complete the KYC process.",
  },
  [CLIENT_ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION]: {
    stepTitle: 'Aadhaar Verification',
    verifiedKey: 'isAadhaarVerified',
    nextStep: CLIENT_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION,
    previousStep: CLIENT_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION,
    nextStepTitle: 'Bank Details',
    stepDescription: 'This is needed as per Govt. of India’s guidelines.',
    formDescription:
      "Enter the client's Aadhaar Number or upload Aadhaar that is linked to their PAN Number to successfully complete the KYC process.",
  },
  [CLIENT_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION]: {
    stepTitle: 'Bank Verification',
    verifiedKey: 'isBankVerified',
    previousStep: CLIENT_ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION,
    nextStepTitle: 'All Done',
    stepDescription: 'The details of the bank you will be investing from, and receive payouts.',
    formDescription:
      'Please provide the bank details associated with the account you wish to invest with and receive payouts to.',
  },
};

export const SORTABLE_COLUMNS = {
  name: 'name',
  email: 'email',
  phone: 'phone',
  pan: 'pan',
  amount: 'investedAmount',
  balance: 'walletBalance',
};

export const CLIENTS_EVENTS = {
  All_Client_Page: 'All_Client_Page',
  Add_new_Client: 'Add_new_Client',
  Add_Bulk_Client: 'Add_Bulk_Client',
  Add_new_Client_From_ID: 'Add_new_Client_From_ID',
  Identity_Verification_Initiated: 'Client_Identity_Verification_Initiated',
  FName_Entry: 'Client_FName_Entry',
  LName_Entry: 'Client_LName_Entry',
  Email_Entry: 'Client_Email_Entry',
  Phone_Entry: 'Client_Phone_Entry',
  Identity_Verification_Success: 'Client_Identity_Verification_Success',
  KYC_PAN_Initiated: 'Client_KYC_PAN_Initiated',
  KYC_PAN_Entry: 'Client_KYC_PAN_Entry',
  KYC_PAN_Verify: 'Client_KYC_PAN_Verify',
  KYC_Aadhaar_Initiated: 'Client_KYC_Aadhaar_Initiated',
  KYC_Aadhaar_Entry: 'Client_KYC_Aadhaar_Entry',
  KYC_Aadhaar_Request_OTP: 'Client_KYC_Aadhaar_Request_OTP',
  KYC_Aadhaar_OTP_Entry: 'Client_KYC_Aadhaar_OTP_Entry',
  KYC_Bank_Initiated: 'Client_KYC_Bank_Initiated',
  KYC_Bank_Account_Entry: 'Client_KYC_Bank_Account_Entry',
  KYC_Bank_Confirm_Account: 'Client_KYC_Bank_Confirm_Account',
  KYC_Bank_IFSC_Entry: 'Client_KYC_Bank_IFSC_Entry',
  KYC_Success_Screen: 'Client_KYC_Success_Screen',
  KYC_Skip: 'Client_KYC_Skip',
};
