export const KYC_STATUS = {
  SCHEDULED: "SCHEDULED",
  REPAID_ON_TIME: "REPAID_ON_TIME",
} as const;

export const SORTABLE_COLUMNS = {
  dealName: "dealName",
  investedOn: "createdAt",
  amount: "amount",
  maturityDate: "payoutDate",
  payoutAmount: "payoutAmount",
};

export const PARTNER_DETAIL_EVENTS = {
  Partner_Overview: "Partner_Overview",
  Partner_Overview_Download_Excel: "Partner_Overview_Download_Excel",
  Partner_Wallet_Options: "Partner_Wallet_Options",
  Partner_Wallet_Balance: "Partner_Wallet_Balance",
  Partner_Wallet_Withdraw: "Partner_Wallet_Withdraw",
  Partner_Wallet_Generate_Link: "Partner_Wallet_Generate_Link",
  Partner_Copy_url: "Partner_Copy_url",
  Partner_View_beneficiary: "Partner_View_beneficiary",
};
