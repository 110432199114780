import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDealDetails, useDealStats } from './useDealDetails';
import { Link, useParams } from 'react-router-dom';
import {
  getRupee,
  getRupeeWithDecimal,
  numDifferentiation,
  twoDecimalNumber,
} from '../../utils/numbers';
import { formatDate } from '../../utils/date';
import Image from '../../assets/Image';
import {
  ArrowBack,
  CreditCardOffIcon,
  VerifiedIcon,
  InfoIcon,
  InfoOutlinedIcon,
  DownloadForOfflineOutlinedIcon,
  TimelineOutlinedIcon,
  ScreenRotationAltOutlinedIcon,
  ArticleOutlinedIcon,
} from '../../assets/mui';
import { DealDocumentType } from '../Deals/types';
import Input from '../../components/Fields/Input';
import KYCButton from '../Onboarding/KYCButton';
import AboutMultiInvoice from '../../assets/about-multi.png';
import ReinvestBanner from '../../assets/reinvest-banner.png';
import { NetworkManager } from '../../services';
import useGetPartnerProfile from '../../hooks/useGetPartnerProfile';
import { kycError } from '../../utils/toast';
import SelectClient from './SelectClient';
import { Dialog, Skeleton } from '@mui/material';
import { useClientVirtualAccount } from '../Clients/useClient';
import MultiTag from './MultiTag';
import DealCompanies from './DealCompanies';
import MultiFaqs from './MultiFaqs';
import Checkbox from '../../components/Fields/Checkbox';
import { DEAL_DETAIL_EVENTS } from './constants';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import { useAnalytics } from '../../hooks/useAnalytics';
import ClientDrawer from '../Clients/ClientDrawer/ClientDrawer';
import toast from 'react-hot-toast';
import { SecurityMeasureCard } from '../../components/molecules/SecurityMeasureCard';
import IDFaqs from './IDFaqs';

interface DealDetailProps {}

const DealDetail: React.FC<DealDetailProps> = () => {
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const [agreement, setAgreement] = useState('');
  const [isInvestmentLoading, setInvestmentLoading] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [amountError, setAmountError] = useState(null);
  const [maxAmount, setMaxAmount] = useState();
  const [investmentAmount, setInvestmentAmount] = useState<string>();
  const [investmentId, setInvestmentId] = useState();
  const [totalReturns, setTotalReturns] = useState(null);
  const [commission, setCommission] = useState(null);
  const [commissionRate, setCommissionRate] = useState(null);
  const [reinvest, setReinvest] = useState(false);
  const [reinvestModal, setReinvestModal] = useState(false);

  const [investmentStatus, setInvestmentStatus] = useState<'error' | 'success' | null>(null);
  const [walletOpen, setWalletOpen] = useState(false);
  const clientId = client?.clientPublicId;
  const { dealId, financeType } = useParams();
  const { data, isLoading } = useDealDetails(dealId, financeType);
  const { data: dealDetails } = data || {};
  const { track } = useAnalytics();

  const isPooling = financeType === 'POOLING';

  const { data: statsData, isLoading: isStatsLoading } = useDealStats(dealId, isPooling);
  const { data: stats } = statsData || {};

  const { data: partner } = useGetPartnerProfile();

  const { data: virtualAccountData, isLoading: virtualAccountLoading } =
    useClientVirtualAccount(clientId);

  const partnerId = partner?.data?.profile?.publicId;

  const {
    accountNumber: walletAccountNumber,
    bankName: walletBankName,
    branchDetails: walletBranchDetails,
    ifscCode: walletIfscCode,
  } = virtualAccountData?.data || {};

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      track({ eventName: 'Deal_Detail_Page_View' });
    }
  }, []);

  useEffect(() => {
    if (dealDetails || stats || partner || virtualAccountData) {
      console.log('Data updated:', {
        dealDetails: !!dealDetails,
        stats: !!stats,
        partner: !!partner,
        virtualAccount: !!virtualAccountData,
      });
    }
  }, [dealDetails, stats, partner, virtualAccountData]);

  const {
    name,
    idealTenure,
    investors,
    maturityDate,
    minInvestment,
    commission: initialCommission,
    commissionPercentage,
    successfullyAmountRaise,
    targetAmountToRaise,
    tenure,
    tenurePeriod,
    constituentDTOList,
  } = dealDetails || {};

  const {
    activeDeals,
    backedByImageUrl,
    clientImageUrl,
    customerName,
    vendorName,
    filledPercentage,
    maturedDeals,
    onTimeRepaymentsPercentage,
    repaymentsDone,
    totalDeals,
    totalListedInDeal,
    totalRaisedByCompany,
    totalRaisedInDeal,
    totalRepayments,
    documents,
  } = stats || {};

  const hasDocuments = !!documents && documents.length > 0;

  const isClientWalletEnough = useMemo(() => {
    return (
      client?.balance >= minInvestment?.amount &&
      client?.balance >= parseInt(investmentAmount || '0')
    );
  }, [minInvestment?.amount, client?.balance, investmentAmount]);

  const copyText = useMemo(() => {
    return `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.):
    Name: ${client?.name}
    Bank Name: ${walletBankName}
    Branch Name: ${walletBranchDetails}
    Account Number: ${walletAccountNumber}
    IFSC Code: ${walletIfscCode}`;
  }, [client?.name, walletBankName, walletBranchDetails, walletAccountNumber, walletIfscCode]);

  const waText = useMemo(() => {
    return `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.): %0a
    Name: ${client?.name} %0a
    Bank Name: ${walletBankName} %0a
    Branch Name: ${walletBranchDetails} %0a
    Account Number: ${walletAccountNumber} %0a
    IFSC Code: ${walletIfscCode}`;
  }, [client?.name, walletBankName, walletBranchDetails, walletAccountNumber, walletIfscCode]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText);
    toast.success('Copied to clipboard');
  };

  const handleInvest = async () => {
    if (investmentAmount && clientId && partnerId) {
      try {
        const investmentResponse = await NetworkManager.Call({
          method: 'post',
          path: NetworkManager.PATH.createInvestment.replace('{{dealId}}', dealId),
          data: {
            amount: investmentAmount,
            clientPublicId: clientId,
            partnerPublicId: partnerId,
            stayInvested: reinvest,
            paymentMode: 'WALLET',
            financeType,
          },
        });
        setInvestmentId(investmentResponse?.data.investmentId);
        setInvestmentStatus('success');
        track({ eventName: DEAL_DETAIL_EVENTS.Investment_Success_Screen });
      } catch (error) {
        setInvestmentStatus('error');
        const errorMessage = error?.response?.data?.message || 'Something went wrong';
        kycError(errorMessage);
      }
    }
  };

  const handleReinvestModal = (e) => {
    e.preventDefault();
    setReinvestModal(true);
  };

  const handleOptIn = async () => {
    setReinvest(true);
    setReinvestModal(false);
    await handleInvest();
  };

  const handleOptOut = () => {
    setReinvest(false);
    setReinvestModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInvestmentLoading(true);
    await handleInvest();
    setInvestmentLoading(false);
  };

  const handleInvestmentAmount = useCallback(
    async (e) => {
      const input = e.target.value;

      // Allow only numbers, a single decimal point and only two decimal places
      const sanitizedAmount = input
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*?)\..*/g, '$1')
        .replace(/(\.\d{2})\d+/g, '$1');

      setInvestmentAmount(sanitizedAmount);

      if (maxAmount && parseFloat(sanitizedAmount) > parseFloat(maxAmount)) {
        setAmountError(`Max investment amount is ₹${twoDecimalNumber(maxAmount)}`);
      } else if (maxAmount && parseFloat(sanitizedAmount) === parseFloat(maxAmount)) {
        setAmountError(null);
      } else {
        setAmountError(null);
        if (!sanitizedAmount || parseFloat(sanitizedAmount) === 0) {
          setAmountError(null);
          setTotalReturns(null);
          setCommission(null);
          setCommissionRate(null);
        } else if (parseFloat(sanitizedAmount) < minInvestment?.amount) {
          setAmountError('Less than minimum investment amount');
          setTotalReturns(null);
          setCommission(null);
          setCommissionRate(null);
        } else {
          const investmentResponse = await NetworkManager.Call({
            method: 'get',
            path: NetworkManager.PATH.investmentTerms.replace('{{dealId}}', dealId),
            params: {
              amount: sanitizedAmount,
              financeType,
            },
          });

          const totalReturns = investmentResponse?.data?.result?.returnAmount;
          setAmountError(null);
          setMaxAmount(investmentResponse?.data?.result?.maxInvestment?.amount);
          if (totalReturns) {
            setTotalReturns(totalReturns);
          }
          const commission = investmentResponse?.data?.result?.commission;
          if (commission) {
            setCommission(commission);
          }
          const commissionRate = investmentResponse?.data?.result?.commissionPercentage;
          if (commissionRate) {
            setCommissionRate(commissionRate);
          }
        }
      }
    },
    [dealId, financeType, minInvestment?.amount, maxAmount],
  );

  const handleClose = () => {
    setInvestmentStatus(null);
    setClient(null);
    setInvestmentAmount(undefined);
    setInvestmentId(undefined);
  };

  const handleWalletOpen = () => {
    track({ eventName: DEAL_DETAIL_EVENTS.Deal_Detail_Wallet_Details });
    setWalletOpen(true);
  };

  const handleWalletClose = () => setWalletOpen(false);

  const handleReinvestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReinvest(event.target.checked);
  };

  const openServiceAgreement = async () => {
    let url;
    if (agreement) {
      url = agreement;
    } else {
      const agreementResponse = await NetworkManager.Call({
        method: 'get',
        path: NetworkManager.PATH.getPartnerDealContract
          .replace('{{financeType}}', financeType)
          .replace('{{dealId}}', dealId),
      });

      setAgreement(agreementResponse?.data.link);

      url = agreementResponse?.data.link;
    }
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
    URL.revokeObjectURL(url);
  };

  // Move useMemo before the conditional return
  const selectClientProps = useMemo(
    () => ({
      client,
      setClient,
      eventName: DEAL_DETAIL_EVENTS.Deal_Detail_Client_Select,
    }),
    [client],
  );

  if (isLoading) return null;

  return (
    <div className="my-6 max-w-7xl mx-auto">
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-12">
        <div className="col-span-2">
          <Link
            to="/deals"
            className="bg-green-700/10 flex items-center justify-center mb-6 p-1.5 h-9 w-9 rounded-full text-green-700"
          >
            <ArrowBack fontSize="small" />
          </Link>
          {/* Heading */}
          <div>
            <div className="w-20 h-20 overflow-hidden rounded bg-zinc-200 mb-6">
              <Image
                // @ts-ignore
                src={dealDetails.company.logoUrl as any}
                alt={dealDetails.name}
                className="w-full h-full"
              />
            </div>
            <div className="flex gap-4 items-center mb-4">
              <h1 className="text-xl font-semibold text-slate-800">
                {isPooling ? name : vendorName}
                {/* TODO: Uncomment after prod data is fixed */}
                {/* <span className="text-slate-600"><ArrowBack /></span> {customerName} */}
              </h1>
              {isPooling && <MultiTag />}
            </div>
            <p className="text-stone-500 max-w-2xl mb-4">{dealDetails.miniSummary}</p>
          </div>
          {/* Company Details */}
          {!isPooling && (
            <div className="flex lg:mt-9">
              <div className="flex mt-auto w-full min-h-0 flex-col bg-black/[.02] rounded-lg border border-stone-300">
                <div className="grid grid-cols-2 xl:grid-cols-4 border-b border-b-stone-300">
                  <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                      Deals done
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                      {maturedDeals} <span className="text-black/30">of {totalDeals}</span>
                    </p>
                  </div>
                  <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Raised</h3>
                    <p className="text-2xl font-medium text-stone-700">
                      {numDifferentiation(totalRaisedByCompany)}
                    </p>
                  </div>
                  <div className="p-4 md:p-6 border-r border-r-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                      Repayments
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                      {repaymentsDone} of <span className="text-black/30">{totalRepayments}</span>
                    </p>
                  </div>
                  <div className="p-4 md:p-6">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                      On time payments
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                      {onTimeRepaymentsPercentage} <span className="text-black/30">%</span>
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:col-span-1">
                  {clientImageUrl && (
                    <div className="p-4 md:p-6 border-r border-r-stone-300">
                      <h3 className="text-stone-700 font-semibold text-xs uppercase mb-4">
                        Clients
                      </h3>
                      <div className="h-16 mix-blend-darken max-w-[300px]">
                        {clientImageUrl && (
                          <Image
                            // @ts-ignore
                            src={clientImageUrl}
                            alt={dealDetails.name}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {backedByImageUrl && (
                    <div className="p-4 md:p-6">
                      <h3 className="text-stone-700 font-semibold text-xs uppercase mb-4">
                        Backed by
                      </h3>
                      <div className="h-16 mix-blend-darken max-w-[300px]">
                        {backedByImageUrl && (
                          <Image
                            // @ts-ignore
                            src={backedByImageUrl}
                            alt={dealDetails.name}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mt-6 md:mt-14">
            <h3 className="text-lg mb-3 text-slate-800 font-medium">Security Measures</h3>
            <div className="grid grid-cols-2 gap-7 mt-6">
              <SecurityMeasureCard
                Icon={TimelineOutlinedIcon}
                tooltipTitle={'Risk Analysis'}
                tooltipContent={
                  'Tap Invest performs a rigorous risk analysis on all the inbound companies and evaluates them against over 50 key parameters, covering everything from financial health and market conditions to regulatory compliance and operational stability. This thorough approach helps ensure that all the deals listed on the platform are well-vetted. However, it is recommended that an individual performs their own analysis before making any investment.'
                }
              />
              <SecurityMeasureCard
                Icon={ScreenRotationAltOutlinedIcon}
                tooltipTitle={'On Demand eNACH'}
                tooltipContent={
                  'On-demand eNACH is enabled for the entire exposure amount of the companies before they can raise capital through Tap Invest. An on-demand eNACH ensures that the payment can be triggered whenever needed, ensuring quick and efficient collections.'
                }
              />
              <SecurityMeasureCard
                Icon={ArticleOutlinedIcon}
                tooltipTitle={'Undated Cheques'}
                tooltipContent={
                  'Undated cheques are collected from the companies for the entire exposure amount before they can raise capital through Tap Invest. These cheques help faciliate seamless and on-time collections for an invoice discounting deal, and account for penalties in rare instances of delay.'
                }
              />
            </div>
          </div>

          {hasDocuments && !isPooling && (
            <div className="mt-6 md:mt-14">
              <h3 className="text-lg mb-3 text-slate-800 font-medium">
                Read more about this company
              </h3>
              <p className="text-stone-500 mb-3">
                Looking for key metrics like <b>funding, traction, financials and more</b>?
              </p>
              <p className="text-stone-500">
                We do a complete deep dive of the companies listed on our platform as a part of our
                diligence process. Download the documents below.
              </p>

              {/* Downloadable files */}
              <div className="mt-4 md:mt-9 flex flex-wrap gap-6">
                {documents &&
                  documents.map((document: DealDocumentType) => (
                    <a href={document.publicLink} target="_blank" download>
                      <div
                        key={document.name}
                        className="border border-stone-200 md:w-96 rounded-lg py-5 px-4 flex justify-between items-center"
                      >
                        <div>
                          <h4 className="text-stone-700 font-medium text-sm">{document.name}</h4>
                          <p className="text-stone-500 text-sm">
                            {document.type}, {document.size}
                          </p>
                        </div>
                        <DownloadForOfflineOutlinedIcon className="text-stone-500" />
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          )}

          {/* Pooling sections */}
          {isPooling && (
            <div className="mt-6">
              {/* How does this work */}
              <div className="bg-stone-200 flex overflow-hidden rounded-lg">
                <Image
                  // @ts-ignore
                  src={AboutMultiInvoice}
                  alt="How multi invoice works"
                  className="h-full max-h-[178px] pr-3 hidden md:block"
                />
                <div className="py-5 px-6">
                  <h3 className="text-stone-700 font-semibold">What is Multi?</h3>
                  <p className="text-stone-500 mt-3">
                    Multi is an investment product designed to offer diversification and reduce risk
                    for investors. It allows you to invest in a pre-selected pool of invoices from a
                    variety of companies instead of focusing on a single issuer.
                  </p>
                </div>
              </div>

              {/* Companies */}
              <div className="mt-9">
                <h2 className="text-stone-800 text-lg font-medium">Company wise Distribution</h2>
                <DealCompanies companies={constituentDTOList} />
              </div>

              {/* Documents */}
              <div className="mt-9">
                <h2 className="text-stone-800 text-lg font-medium mb-2">Documents</h2>
                <a
                  href="https://cdn.tapinvest.in/Multi-ID-Doc.pdf"
                  target="_blank"
                  download
                  className="block md:w-96"
                >
                  <div className="border border-stone-200 md:w-96 rounded-lg py-5 px-4 flex justify-between items-center">
                    <div>
                      <h4 className="text-stone-700 font-medium text-sm">
                        Multi Invoice discounting
                      </h4>
                      <p className="text-stone-500 text-sm mt-1">
                        Read more about how we research into companies.
                      </p>
                    </div>
                    <DownloadForOfflineOutlinedIcon className="text-stone-500" />
                  </div>
                </a>
              </div>
            </div>
          )}
          {/* FAQs */}
          <div className="mt-9">
            <h2 className="text-stone-800 text-lg font-medium">Frequently Asked Questions</h2>
            {isPooling ? <MultiFaqs /> : <IDFaqs />}
          </div>
        </div>

        {/* Statistics / Create New Order */}
        <form
          className="lg:mt-12 max-lg:col-span-2"
          onSubmit={reinvest ? handleReinvestModal : handleSubmit}
        >
          <div className="flex flex-col bg-black/[.02] rounded-lg border border-stone-300">
            <div className="">
              {/* Order Investment Input */}
              <div className="border-b relative border-b-stone-300 text-slate-600 overflow-hidden h-24">
                <label className="absolute z-10 top-4 left-3 tracking-wider uppercase text-xs font-semibold text-black/40">
                  Investment amount
                </label>
                <Input
                  value={investmentAmount || ''}
                  prefixIcon={<span className="text-black/40 font-medium text-xl pt-3">₹</span>}
                  type="text"
                  placeholder={minInvestment.amount || ''}
                  className="h-full font-semibold text-2xl placeholder:text-stone-400 pt-6 border-none rounded-b-none"
                  containerClassName="h-full border-none rounded-b-none"
                  onChange={handleInvestmentAmount}
                />
                {!!amountError && (
                  <span className="text-red-500 text-xs mt-1 absolute bottom-3 left-8">
                    {amountError}
                  </span>
                )}
              </div>
              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Total Returns</h3>
                <span className="text-lg font-medium line-clamp-1">
                  {totalReturns
                    ? getRupeeWithDecimal.format(totalReturns)
                    : getRupeeWithDecimal.format(minInvestment.returnAmount)}
                </span>
              </div>

              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Minimum Investment</h3>
                <span className="text-lg font-medium">
                  {minInvestment.amount ? getRupee.format(minInvestment.amount) : '-'}
                </span>
              </div>
              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Yield</h3>
                <span className="text-lg font-medium">
                  {minInvestment.netIRR ? `${twoDecimalNumber(minInvestment.netIRR)}%` : '-'}
                </span>
              </div>
              <div className="p-4 md:p-5 flex border-b border-b-stone-300 items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Payout on</h3>
                <span className="text-lg font-medium">
                  {maturityDate ? formatDate(maturityDate) : '-'}
                </span>
              </div>
              <div className="p-4 md:p-5 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36 font-semibold text-green-700">Your Commission</h3>
                <span className="text-lg font-semibold text-green-700 line-clamp-1">
                  {commission
                    ? getRupeeWithDecimal.format(commission)
                    : getRupeeWithDecimal.format(initialCommission)}{' '}
                  <span className="font-medium text-sm text-stone-400">
                    ({commissionRate ? commissionRate : commissionPercentage}%)
                  </span>
                </span>
              </div>
            </div>
          </div>
          {dealDetails?.company?.reinvestment && (
            <div className="mt-6">
              <div className="flex justify-between items-center rounded-md border border-stone-300 bg-black/[.02] p-5">
                <div className="flex gap-1 items-center">
                  <Tooltip
                    title="Automatically reinvest your payout in the same company. Your payout will be reinvested only if the XIRR is equal to or greater than the current investment and the tenure remains the same."
                    placement="top"
                    arrow
                  >
                    <span className="underline text-slate-600 text-sm">Re-invest</span>
                  </Tooltip>
                  <span className="text-[8px] text-green-700 uppercase font-semibold py-0.5 px-1 bg-green-700/10">
                    New
                  </span>
                </div>
                <div>
                  <Switch
                    checked={reinvest}
                    onChange={handleReinvestChange}
                    classes={{
                      track: reinvest && '!bg-green-600 !opacity-100',
                      switchBase: '!text-white',
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={`mt-6 `}>
            <SelectClient {...selectClientProps} />
          </div>
          {!!client && client?.balance < parseInt(investmentAmount) && (
            <p className="text-amber-700 mt-1.5 mb-2 text-sm font-semibold flex items-center gap-1">
              <InfoIcon fontSize="small" /> There's insufficient money in the client's wallet
            </p>
          )}
          <div className="mt-4">
            <Checkbox
              id="acceptTerms"
              className="accent-green-600"
              label={
                <span>
                  By proceeding you agree to our{' '}
                  <a href="/terms" target="_blank" className="underline">
                    Terms & Conditions
                  </a>{' '}
                  {!isPooling && (
                    <span>
                      and{' '}
                      <button onClick={openServiceAgreement} className="underline">
                        Service Agreement
                      </button>
                    </span>
                  )}
                </span>
              }
              labelClassName="text-stone-500"
              defaultChecked
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
          </div>
          <div className="mt-4 flex justify-between items-center gap-2.5">
            {!!client && !isClientWalletEnough && (
              <button
                onClick={handleWalletOpen}
                type="button"
                className="bg-white shadow flex-shrink-0 flex items-center gap-2 text-sm text-gray-700 border border-gray-300 rounded-xl font-semibold h-10 w-fit px-4"
              >
                View Wallet Details
              </button>
            )}
            <KYCButton
              text="Create New Order"
              isLoading={isInvestmentLoading}
              className="!mt-0 h-10 !py-0 shadow"
              disabled={
                !investmentAmount ||
                parseInt(investmentAmount) < minInvestment.amount ||
                !clientId ||
                !partnerId ||
                !isClientWalletEnough ||
                !!amountError ||
                !acceptedTerms
              }
            />
          </div>
        </form>

        {/* Client Wallet Details */}
        <Dialog open={walletOpen} maxWidth="sm" fullWidth onClose={handleWalletClose}>
          <div className="py-5 border bg-white border-zinc-200 rounded-lg">
            <div className="px-5 border-b border-b-black/10 pb-6">
              {virtualAccountLoading ? (
                <Skeleton width={80} height={40} />
              ) : (
                <h3 className="text-lg font-semibold">Wallet Details</h3>
              )}
              <p className="text-slate-600 text-sm mt-1.5">
                Your client needs to add money to the following beneficiary, to make investments.
              </p>
            </div>
            <div className="p-5 flex flex-col gap-3">
              <div className="flex items-center">
                <h4 className="text-sm text-gray-600 w-36">Name:</h4>
                <h3 className="text-sm font-medium">{client?.name}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-sm text-gray-600 w-36">Bank Name:</h4>
                <h3 className="text-sm font-medium">{walletBankName}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-sm text-gray-600 w-36">Branch Name:</h4>
                <h3 className="text-sm font-medium">{walletBranchDetails}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-sm text-gray-600 w-36">Account Number:</h4>
                <h3 className="text-sm font-medium">{walletAccountNumber}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-sm text-gray-600 w-36">IFSC Code:</h4>
                <h3 className="text-sm font-medium">{walletIfscCode}</h3>
              </div>
            </div>
            <div className="px-5 pt-6 border-t border-black/10">
              <div className="flex items-center gap-3 justify-end">
                <a
                  href={`whatsapp://send?text=${waText}`}
                  target="_blank"
                  className="bg-white text-slate-600 border border-black/10 shadow flex items-center gap-2 text-sm rounded-xl font-semibold px-4 h-10"
                >
                  Share via WhatsApp
                </a>
                <button
                  onClick={copyToClipboard}
                  className="bg-green-700 text-white text-sm font-semibold shadow h-10 px-4 rounded-xl"
                >
                  Copy All
                </button>
              </div>
            </div>
          </div>
        </Dialog>

        {/* Investment Status */}
        <Dialog open={!!investmentStatus} maxWidth="sm" fullWidth onClose={handleClose}>
          <div className="p-4 md:p-6 border bg-white border-zinc-200 rounded-lg">
            <div className="mb-4">
              {investmentStatus == 'success' && <VerifiedIcon className="text-green-700" />}
              {investmentStatus == 'error' && <CreditCardOffIcon className="text-red-700" />}
            </div>
            <div>
              <h3 className="font-semibold text-xl">
                {investmentStatus == 'success'
                  ? 'Thank you for investing with Tap Invest!'
                  : 'There was an error processing the payment.'}
              </h3>
              <p className="text-slate-600 text-sm max-w-md mt-4">
                {investmentStatus == 'success' ? (
                  <span>
                    {' '}
                    Your investment is being deployed as we speak. You can view your earning and
                    investment status in{' '}
                    <Link
                      to={`/clients/${client?.clientPublicId}`}
                      onClick={handleClose}
                      className="underline"
                    >
                      Dashboard
                    </Link>
                  </span>
                ) : (
                  'We apologise for the inconvenience caused, as there seems to be an issue on our end. Any money debited will be refunded.'
                )}
              </p>
              {investmentStatus == 'success' && (
                <p className="text-slate-600 font-semibold mt-4">
                  Your Investment ID: {investmentId}
                </p>
              )}

              {investmentStatus == 'success' ? (
                <div className="flex gap-4">
                  <button
                    className="text-stone-600 bg-white mt-6 ml-auto flex items-center border border-black/10 h-10 text-sm font-semibold px-7 w-fit rounded-xl shadow-sm"
                    onClick={handleClose}
                  >
                    Invest More
                  </button>
                  <Link to={`/clients/${client?.clientPublicId}`} onClick={handleClose}>
                    <button className="bg-green-700 mt-6 ml-auto flex items-center h-10 text-white text-sm font-semibold px-7 w-fit rounded-xl shadow">
                      Go to Dashboard
                    </button>
                  </Link>
                </div>
              ) : (
                <Link to={'/investments'} onClick={handleClose}>
                  <button className="bg-green-700 mt-6 ml-auto flex items-center h-10 text-white text-sm font-semibold px-7 w-fit rounded-xl shadow">
                    Understood
                  </button>
                </Link>
              )}
            </div>
          </div>
        </Dialog>

        {/* Reinvest Modal */}
        <Dialog
          maxWidth="md"
          fullWidth
          open={reinvestModal}
          onClose={() => setReinvestModal(false)}
        >
          <div className="grid lg:grid-cols-4">
            <div className="hidden lg:block col-span-1">
              <img src={ReinvestBanner} alt="Re-invest" className="h-full" />
            </div>
            <div className="lg:col-span-3">
              <div className="p-6 border-b border-b-stone-200">
                <h4 className="text-green-700 uppercase font-semibold text-xs">New feature</h4>
                <h2 className="text-xl font-semibold my-1.5">
                  Reinvest for your client ' {client?.name} '
                </h2>
                <div className="flex items-center gap-8">
                  <p className="text-stone-800 text-xs">
                    <span className="text-gray-700 font-medium opacity-40">Pan Number</span>{' '}
                    {client?.pan}
                  </p>
                  <p className="text-stone-800 text-xs">
                    <span className="text-gray-700 font-medium opacity-40">Phone Number</span>{' '}
                    {client?.phone}
                  </p>
                </div>
              </div>
              <div className="p-6">
                <p className="text-stone-500">
                  By enabling reinvest, your client's payout will automatically be invested again in
                  the same company on better or same terms.
                </p>

                <div className="flex justify-between items-center gap-10 mt-5">
                  <p className="text-stone-700 font-medium flex flex-col gap-1">
                    <span className="uppercase text-stone-400 font-semibold text-xs">Company</span>{' '}
                    {dealDetails?.company?.brandName}
                  </p>
                  <p className="text-stone-700 font-medium flex flex-col gap-1">
                    <span className="uppercase text-stone-400 font-semibold text-xs">IRR</span>{' '}
                    {minInvestment.netIRR}
                  </p>
                  <p className="text-stone-700 font-medium flex flex-col gap-1">
                    <span className="uppercase text-stone-400 font-semibold text-xs">Tenure</span>{' '}
                    {dealDetails?.tenure}
                  </p>
                </div>

                <div className="flex flex-col gap-2 mt-14">
                  <div className="px-2 text-stone-500 bg-stone-100 py-3 rounded-md font-medium text-xs flex items-center gap-2">
                    <InfoOutlinedIcon fontSize="small" />
                    <span>
                      By clicking "Proceed" you agree to the{' '}
                      <a href="/terms" target="_blank" className="underline">
                        Terms & Conditions
                      </a>
                      .
                    </span>
                  </div>
                  <div className="px-2 py-3 text-green-700 bg-green-600/[0.08] rounded-md font-medium text-xs flex items-center gap-2">
                    <InfoOutlinedIcon fontSize="small" />
                    <span>You can always opt out later, upto 2 days before payouts.</span>
                  </div>
                </div>
              </div>
              <div className="flex w-full p-6 border-t border-t-stone-200 justify-end gap-2">
                <button
                  className="bg-white shadow-sm border border-slate-200 rounded-xl h-10 px-7 text-sm text-slate-600 font-semibold"
                  onClick={handleOptOut}
                  type="button"
                >
                  Opt out
                </button>
                <button
                  className="bg-green-600 rounded-xl h-10 px-7 text-sm text-white font-semibold"
                  type="button"
                  onClick={handleOptIn}
                >
                  Opt In & Proceed
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
      <ClientDrawer />
    </div>
  );
};

DealDetail.displayName = 'DealDetail';

export default React.memo(DealDetail);
