export const KYC_STATUS = {
  SCHEDULED: "SCHEDULED",
  REPAID_ON_TIME: "REPAID_ON_TIME",
} as const;

export const SORTABLE_COLUMNS = {
  dealName: "dealName",
  investedOn: "createdAt",
  amount: "amount",
  maturityDate: "payoutDate",
  payoutAmount: "payoutAmount",
};

export const CLIENT_DETAIL_EVENTS = {
  Client_Overview: 'Client_Overview',
  Client_Overview_Download_Excel: 'Client_Overview_Download_Excel',
  Client_Wallet_Options: 'Client_Wallet_Options',
  Client_Wallet_Add_Money: 'Client_Wallet_Add_Money',
  Client_Wallet_Withdraw: 'Client_Wallet_Withdraw',
  Client_Wallet_Withdraw_Status: 'Client_Wallet_Withdraw_Status',
  Client_Wallet_Generate_Link: 'Client_Wallet_Generate_Link',
  Client_Copy_url: 'Client_Copy_url',
  Client_View_beneficiary: 'Client_View_beneficiary',
  Client_Tab_Change: 'Client_Tab_Change',
};
