import React, { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '../../../hooks/useAnalytics';
import clsx from 'clsx';
import { GOLD_EVENTS } from './constants';
import KYCButton from '../../Onboarding/KYCButton';
import Input from '../../../components/Fields/Input';
import useGetPartnerProfile from '../../../hooks/useGetPartnerProfile';
import {
  fiveDecimalNumberWithoutRounding,
  fourDecimalNumberWithoutRounding,
  getRupeeWithDecimal,
  twoDecimalNumberWithoutRounding,
} from '../../../utils/numbers';
import SelectClient from '../SelectClient';
import { Checkbox, Dialog, DialogContent, DialogTitle, Skeleton, Typography } from '@mui/material';
import ViewDetails from './ViewDetails';
import { CloseOutlinedIcon } from '../../../assets/mui';
import toast from 'react-hot-toast';
import {
  generateMetalPaymentLink,
  getMetalLivePrice,
  getPartnerMetalCommission,
  postSellMetal,
  useMetalTransaction,
} from '../useDealDetails';
import { TimerComponent } from './components/TimerComponent';
import ClientDrawer from '../../Clients/ClientDrawer/ClientDrawer';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info'; // Importing from Material UI (you can use your own icons)
import RocketIcon from '@mui/icons-material/Rocket';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MessageCycler from './components/MessageCycler';

const messages = [
  {
    text: 'If the time expires while you complete the payment, you will receive gold based on the updated price.',
    icon: <InfoIcon className="text-amber-700" style={{ width: '14px', height: '14px' }} />,
  },
  {
    text: 'Current live price gets updated every 5 Minutes.',
    icon: <RocketIcon className="text-amber-700" style={{ width: '14px', height: '14px' }} />,
  },
  {
    text: 'The buying and selling prices may fluctuate based on live market conditions.',
    icon: <LocalOfferIcon className="text-amber-700" style={{ width: '14px', height: '14px' }} />,
  },
  {
    text: 'The purchased Gold can be sold only after 2 days of purchase',
    icon: <InfoIcon className="text-amber-700" style={{ width: '14px', height: '14px' }} />,
  },
];

const DigitalGold = () => {
  const { track } = useAnalytics();
  const location = useLocation();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get('tab');

  const [plan, setPlan] = useState('BUY_GOLD');

  const [isInvestmentLoading, setIsInvestmentLoading] = useState(false);
  const [userPaymentLink, setUserPaymentLink] = useState(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [metalLivePrice, setMetalLivePrice] = useState<any>(null);
  const [metalLivePriceLoading, setMetalLivePriceLoading] = useState(false);
  const [partnerCommission, setPartnerCommission] = useState<any>(null);
  const [partnerCommissionLoading, setPartnerCommissionLoading] = useState(false);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(true);
  const [fetchClients, setFetchClients] = useState(false);
  const { data: partner } = useGetPartnerProfile();

  const partnerId = partner?.data?.profile?.publicId;
  const clientId = client?.clientPublicId;
  const [isGramSelected, setIsGramSelected] = useState(true); // State to track whether gram is selected
  const [selectedGram, setSelectedGram] = useState(1);
  const [selectedAmount, setSelectedAmount] = useState(0); // what user enters also will become our amountPayable
  const [gstAmount, setGstAmount] = useState(0); // livePrice + 3% GST per gm
  const [totalAmountWithoutGst, setTotalAmountWithoutGst] = useState(0);
  const [sellMaxGold, setSellMaxGold] = useState(false);

  const fetchLivePrice = async () => {
    setMetalLivePrice(null);
    setMetalLivePriceLoading(true);
    await getMetalLivePrice()
      .then((resp) => {
        setMetalLivePrice(resp);
        setGstAmount(resp?.data?.goldBuyPrice + (resp?.data?.goldBuyPrice * 3) / 100);
      })
      .finally(() => {
        setMetalLivePriceLoading(false);
      });
  };

  const fetchPartnerCommission = useCallback(async () => {
    setPartnerCommission(null);
    setPartnerCommissionLoading(true);
    if (!selectedAmount) {
      return;
    }
    await getPartnerMetalCommission({
      amount: selectedAmount ? Number(selectedAmount) : 0,
      metal: 'GOLD',
    })
      .then((resp) => {
        setPartnerCommission(resp);
      })
      .finally(() => {
        setPartnerCommissionLoading(false);
      });
  }, [selectedAmount]);

  useEffect(() => {
    if (tabValue === 'GOLD') {
      fetchLivePrice();
    }
  }, [tabValue]); // don't add dependencies here

  useEffect(() => {
    if (plan === 'BUY_GOLD') {
      if (isGramSelected) {
        setGstAmount(
          twoDecimalNumberWithoutRounding(
            selectedGram *
              (metalLivePrice?.data?.goldBuyPrice + (metalLivePrice?.data?.goldBuyPrice * 3) / 100),
          ),
        );
        setTotalAmountWithoutGst(
          twoDecimalNumberWithoutRounding(selectedGram * metalLivePrice?.data?.goldBuyPrice),
        );
      } else {
        setGstAmount(twoDecimalNumberWithoutRounding(selectedAmount));
        setTotalAmountWithoutGst(twoDecimalNumberWithoutRounding(selectedAmount / (1 + 3 / 100)));
      }
    }
  }, [metalLivePrice?.data?.goldBuyPrice, selectedAmount, selectedGram]);

  useEffect(() => {
    if (plan === 'BUY_GOLD') {
      setSelectedAmount(
        twoDecimalNumberWithoutRounding(
          selectedGram *
            (metalLivePrice?.data?.goldBuyPrice + (metalLivePrice?.data?.goldBuyPrice * 3) / 100),
        ),
      );
    } else if (plan === 'SELL_GOLD') {
      setSelectedAmount(
        twoDecimalNumberWithoutRounding(selectedGram * metalLivePrice?.data?.goldSellPrice),
      );
    }
    // only call Partner commission api if both amount and gram are selected
    if (totalAmountWithoutGst) {
      fetchPartnerCommission();
    }
  }, [metalLivePrice?.data?.goldBuyPrice, metalLivePrice?.data?.goldSellPrice, plan, sellMaxGold]);

  const handleSwap = () => {
    setIsGramSelected(!isGramSelected);
  };

  const {
    data: transactionList,
    isLoading: transactionLoading,
    refetch: transactionRefetch,
  } = useMetalTransaction();

  useEffect(() => {
    if (client?.metalBlocked) {
      toast.dismiss();
      toast.error('Investment for the selected Clients are blocked, please contact RM', {
        duration: 2000,
        position: 'top-center',
      });
    }
  }, [client]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    toast.dismiss();
    setSellMaxGold(false);

    if (isNaN(value)) {
      toast.error('only numbers allowed', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }

    if (!isGramSelected && value.includes('.') && value.split('.')[1].length > 2) {
      toast.error('For Amount only up to 2 decimal places are allowed', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }
    if (isGramSelected && value.includes('.') && value.split('.')[1].length > 4) {
      toast.error('For Grams only up to 4 decimal places are allowed', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }

    if (!isGramSelected && Number(value) > 5000000 && plan === 'BUY_GOLD') {
      toast.error('Maximum amount is 50Lakh', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }

    if (!isGramSelected && Number(value) > 1000000 && plan === 'SELL_GOLD') {
      toast.error('Maximum amount is 10Lakh', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }

    if (isGramSelected && Number(value) > 500 && plan === 'BUY_GOLD') {
      toast.error('Please input amount if you want to add more', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }
    if (isGramSelected && Number(value) > 150 && plan === 'SELL_GOLD') {
      toast.error('Please input amount if you want to add more', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }

    if (isGramSelected) {
      setSelectedGram(value);
      if (plan === 'BUY_GOLD') {
        setSelectedAmount(
          twoDecimalNumberWithoutRounding(
            value *
              (metalLivePrice?.data?.goldBuyPrice + (metalLivePrice?.data?.goldBuyPrice * 3) / 100),
          ),
        );
      } else if (plan === 'SELL_GOLD') {
        setSelectedAmount(
          twoDecimalNumberWithoutRounding(value * metalLivePrice?.data?.goldSellPrice),
        );
      }
    } else {
      setSelectedAmount(value);
      if (plan === 'BUY_GOLD') {
        setSelectedGram(
          fourDecimalNumberWithoutRounding(
            value /
              (metalLivePrice?.data?.goldBuyPrice + (metalLivePrice?.data?.goldBuyPrice * 3) / 100),
          ),
        );
      } else if (plan === 'SELL_GOLD') {
        setSelectedGram(
          fiveDecimalNumberWithoutRounding(value / metalLivePrice?.data?.goldSellPrice),
        );
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsInvestmentLoading(true);
    setFetchClients(false);
    if (!client) {
      toast.error('Please select a client', {
        duration: 2000,
        position: 'top-center',
      });
      setIsInvestmentLoading(false);
      return;
    }

    if (isCheckboxSelected === false) {
      toast.error('Please agree to the terms and conditions', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }

    if (selectedAmount === 0 || selectedGram === 0) {
      toast.error('Please enter the amount', {
        duration: 2000,
        position: 'top-center',
      });
      setIsInvestmentLoading(false);
      return;
    }
    if (selectedAmount < 100 && !sellMaxGold) {
      toast.error('Please select minimum 100rs.', {
        duration: 2000,
        position: 'top-center',
      });
      setIsInvestmentLoading(false);
      return;
    }
    if (plan === 'SELL_GOLD') {
      if (client?.goldInGms < selectedGram) {
        toast.error('Client does not have enough gold to sell', {
          duration: 2000,
          position: 'top-center',
        });
        setIsInvestmentLoading(false);
        return;
      }

      // call sell gold api
      postSellMetal({
        price: metalLivePrice?.data?.goldSellPrice,
        blockId: metalLivePrice?.data?.blockId,
        metalType: 'GOLD',
        quantity: selectedGram,
        amount: selectedAmount,
        clientPublicId: clientId,
      })
        .then((res) => {
          if (res) {
            toast.success('Gold sold successfully', {
              duration: 2000,
              position: 'top-center',
            });
          }
          setFetchClients(true);
          transactionRefetch();
          setSelectedGram(0);
          setSelectedAmount(0);
          setClient(null);
          setSellMaxGold(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message || 'Error selling gold', {
            duration: 2000,
            position: 'top-center',
          });
        })
        .finally(() => {
          setIsInvestmentLoading(false);
        });
      return;
    }

    if (selectedAmount && clientId && partnerId) {
      generateMetalPaymentLink({
        clientPublicId: clientId,
        price: metalLivePrice?.data?.goldBuyPrice,
        blockId: metalLivePrice?.data?.blockId,
        quantity: selectedGram,
        amount: totalAmountWithoutGst,
        metal: 'GOLD',
        amountPayable: gstAmount,
      })
        .then((res) => {
          if (res) {
            setUserPaymentLink(res?.data);
            setIsPaymentOpen(true);
          }
        })
        .catch((err) => {
          toast.error('Error generating payment link', {
            duration: 2000,
            position: 'top-center',
          });
        })
        .finally(() => {
          track({
            eventName: GOLD_EVENTS.GENERATE_PAYMENT_LINK,
            eventProperties: {
              clientId: clientId,
              partnerId: partnerId,
              amount: selectedAmount,
              gram: selectedGram,
              metal: 'GOLD',
            },
          });
        });
    }
    setIsInvestmentLoading(false);
  };

  const handlePaymentClose = () => {
    setIsPaymentOpen(false);
    setUserPaymentLink(null);
  };

  useEffect(() => {
    if (selectedAmount && selectedGram) {
      fetchPartnerCommission();
    }
  }, [fetchPartnerCommission, selectedAmount, selectedGram, plan]);

  useEffect(() => {
    if (metalLivePrice?.data?.expiresAt) {
      const expiresAtTime = new Date(metalLivePrice?.data?.expiresAt).getTime();
      const currentTime = new Date().getTime();
      const timeLeft = expiresAtTime - currentTime;

      if (timeLeft > 0) {
        const timer = setTimeout(() => {
          fetchLivePrice();
          if (selectedAmount && selectedGram) {
            fetchPartnerCommission();
          }
        }, timeLeft);

        return () => clearTimeout(timer);
      }
    }
  }, [metalLivePrice?.data?.expiresAt]);

  return (
    <div className="my-6 max-w-7xl mx-auto">
      <div className="grid grid-cols-2 xl:grid-cols-5 gap-8 xl:gap-16">
        {/* left hand section */}
        <div className="col-span-3">
          {tabValue === 'GOLD' && (
            <ViewDetails
              metalLivePrice={metalLivePrice}
              metalLivePriceLoading={metalLivePriceLoading}
              transactionList={transactionList}
              transactionLoading={transactionLoading}
              transactionRefetch={transactionRefetch}
            />
          )}
        </div>
        {/* right hand section */}
        <form className="col-span-2" onSubmit={onSubmit}>
          <div className="mb-6">
            <div className="flex gap-3">
              <button
                type="button"
                className={clsx('uppercase rounded-full py-1.5 px-3 font-semibold text-xs', {
                  'bg-amber-600 text-white': plan === 'BUY_GOLD',
                  'bg-amber-600/15 text-amber-600': plan === 'SELL_GOLD',
                })}
                onClick={() => {
                  setPlan('BUY_GOLD');
                  setClient(null);
                  setSellMaxGold(false);
                  setSelectedGram(0);
                  setSelectedAmount(0);
                  setGstAmount(0);
                  // setIsGramSelected(true);
                  track({ eventName: GOLD_EVENTS.BUY_GOLD });
                }}
              >
                Buy Gold
              </button>
              <button
                type="button"
                className={clsx('uppercase rounded-full py-1.5 px-3 font-semibold text-xs', {
                  'bg-amber-600 text-white': plan === 'SELL_GOLD',
                  'bg-amber-600/15 text-amber-600': plan === 'BUY_GOLD',
                })}
                onClick={() => {
                  setPlan('SELL_GOLD');
                  setClient(null);
                  setSellMaxGold(false);
                  setSelectedGram(0);
                  setSelectedAmount(0);
                  setGstAmount(0);
                  // setIsGramSelected(true);
                  track({ eventName: GOLD_EVENTS.SELL_GOLD });
                }}
              >
                Sell Gold
              </button>
            </div>
          </div>
          <div className="flex flex-col bg-black/[.02] rounded-lg border border-stone-300">
            {plan === 'BUY_GOLD' ? (
              <div>
                {/* Order Investment Input */}
                <div className="border-b relative border-b-stone-300 text-slate-600 overflow-hidden h-24 bg-white rounded-t-lg">
                  <label className="absolute z-10 top-4 left-3 tracking-wider uppercase text-xs font-semibold text-black/40">
                    Investment {isGramSelected ? 'Quantity' : 'Amount'}
                  </label>

                  <div className="flex items-center justify-between absolute z-10 top-10 left-2">
                    {/* Input field */}
                    <Input
                      value={isGramSelected ? selectedGram : selectedAmount}
                      onChange={handleInputChange}
                      prefixIcon={
                        <span className="text-black/40 font-medium text-xl pt-3">
                          {isGramSelected ? 'gms' : '₹'}
                        </span>
                      }
                      longPrefix={isGramSelected}
                      type="text"
                      placeholder={''}
                      className="font-semibold text-2xl placeholder:text-stone-400 pt-6 border-none rounded-b-none"
                      containerClassName="border-none rounded-b-none !shadow-none "
                    />
                    {/* Display calculated amount */}
                    <div className="flex items-center mr-4">
                      <span className="text-sm font-medium min-w-[80px] mr-2">
                        {metalLivePriceLoading ? (
                          <Skeleton width={100} animation="wave" />
                        ) : isGramSelected ? (
                          gstAmount ? (
                            `${getRupeeWithDecimal.format(gstAmount)}`
                          ) : (
                            '-'
                          )
                        ) : selectedGram ? (
                          `${selectedGram} gms`
                        ) : (
                          '-'
                        )}
                      </span>

                      {/* Swap button */}
                      <button
                        onClick={handleSwap}
                        className="text-green-800 underline text-xs font-semibold"
                        style={{
                          cursor: 'pointer',
                        }}
                        type="button"
                        // disabled={selectedAmount === 0 || selectedGram === 0}
                      >
                        Swap
                      </button>
                    </div>
                  </div>

                  <div className="h-fit w-fit border border-stone-300 rounded-full text-red-700 absolute z-10 top-2 right-3 inset-y-0 text-xs flex px-2 py-[3px]">
                    Price valid for{' '}
                    {metalLivePrice?.data?.expiresAt ? (
                      <Typography
                        sx={{
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                        }}
                      >
                        <TimerComponent expiresAt={metalLivePrice?.data?.expiresAt} />
                      </Typography>
                    ) : (
                      <Skeleton animation="wave" width="50px" sx={{ ml: 1 }} />
                    )}
                  </div>
                </div>

                <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                  <h3 className="text-sm min-w-36">Live Price</h3>
                  <span className="text-lg font-medium line-clamp-1">
                    {metalLivePriceLoading ? (
                      <Skeleton width={100} animation="wave" />
                    ) : metalLivePrice?.data ? (
                      getRupeeWithDecimal.format(metalLivePrice?.data?.goldBuyPrice)
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
                <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                  <h3 className="text-sm min-w-36">GST</h3>
                  <span className="text-lg font-medium line-clamp-1">3%</span>
                </div>
                <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                  <h3 className="text-sm min-w-36">Buying Price</h3>
                  <span className="text-lg font-medium">
                    <span className="text-lg font-medium">
                      {metalLivePriceLoading ? (
                        <Skeleton width={100} animation="wave" />
                      ) : metalLivePrice?.data ? (
                        getRupeeWithDecimal.format(gstAmount)
                      ) : (
                        '-'
                      )}
                    </span>
                  </span>
                </div>
                <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-green-700">
                  <h3 className="text-sm min-w-36">Your Commission</h3>
                  <span className="text-lg font-medium">
                    {partnerCommissionLoading ? (
                      <Skeleton width={100} animation="wave" />
                    ) : partnerCommission?.data ? (
                      fourDecimalNumberWithoutRounding(
                        partnerCommission?.data?.earnedIncomeForInvestment,
                      )
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <div>
                {/* Order Investment Input */}
                <div className="border-b relative border-b-stone-300 text-slate-600 overflow-hidden h-32 bg-white rounded-t-lg">
                  <label className="absolute z-10 top-4 left-3 tracking-wider uppercase text-xs font-semibold text-black/40">
                    Investment {isGramSelected ? 'Quantity' : 'Amount'}
                  </label>
                  <div className="absolute z-10 top-10 left-2">
                    <div className=" flex items-center ">
                      {/* Input field */}
                      <Input
                        value={isGramSelected ? selectedGram : selectedAmount}
                        onChange={handleInputChange}
                        prefixIcon={
                          <span className="text-black/40 font-medium text-xl pt-3">
                            {isGramSelected ? 'gms' : '₹'}
                          </span>
                        }
                        longPrefix={isGramSelected}
                        onClick={() => {
                          track({
                            eventName: GOLD_EVENTS.ENTER_GOLD_AMOUNT,
                            eventProperties: {
                              amount: selectedAmount,
                              clientId: clientId,
                              partnerId: partnerId,
                            },
                          });
                        }}
                        type="text"
                        placeholder={''}
                        className="font-semibold text-2xl placeholder:text-stone-400 pt-6 border-none rounded-b-none"
                        containerClassName="border-none rounded-b-none !shadow-none "
                      />

                      {/* Display calculated amount */}
                      <div className="flex items-center mr-4">
                        <span className="text-sm font-medium min-w-[80px] mr-2">
                          {metalLivePriceLoading ? (
                            <Skeleton width={100} animation="wave" />
                          ) : isGramSelected ? (
                            selectedAmount ? (
                              `₹${selectedAmount}`
                            ) : (
                              '-'
                            )
                          ) : selectedGram ? (
                            `${selectedGram} gms`
                          ) : (
                            '-'
                          )}
                        </span>

                        {/* Swap button */}
                        <button
                          onClick={handleSwap}
                          className="text-green-800 underline text-xs font-semibold"
                          type="button"
                          style={{
                            cursor: 'pointer',
                          }}
                          // disabled={
                          // selectedAmount === 0 || selectedGram === 0 || metalLivePriceLoading
                          // }
                        >
                          Swap
                        </button>
                      </div>
                    </div>

                    <div>
                      <button
                        type="button"
                        className={clsx(
                          'rounded-full py-1 px-2 font-semibold text-[10px] mt-2 ml-2',
                          client && client?.goldInGms
                            ? 'bg-amber-600 text-white'
                            : 'bg-stone-200 text-white',
                        )}
                        disabled={!client}
                        onClick={() => {
                          setSellMaxGold(true);
                          setSelectedGram(client?.goldInGms);
                        }}
                      >
                        Sell Max
                      </button>
                    </div>
                  </div>

                  <div className="h-fit w-fit border border-stone-300 rounded-full text-red-700 absolute z-10 top-3 right-3 inset-y-0 text-xs flex px-2 py-[3px]">
                    Price valid for{' '}
                    {metalLivePrice?.data?.expiresAt ? (
                      <Typography
                        sx={{
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                        }}
                      >
                        <TimerComponent expiresAt={metalLivePrice?.data?.expiresAt} />
                      </Typography>
                    ) : (
                      <Skeleton animation="wave" width="50px" sx={{ ml: 1 }} />
                    )}
                  </div>
                </div>

                <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                  <h3 className="text-sm min-w-36">Live Price</h3>
                  <span className="text-lg font-medium line-clamp-1">
                    {metalLivePriceLoading ? (
                      <Skeleton width={100} animation="wave" />
                    ) : metalLivePrice?.data ? (
                      getRupeeWithDecimal.format(metalLivePrice?.data.goldSellPrice) + '/gm'
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
                <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                  <h3 className="text-sm min-w-36">Sell Price</h3>
                  <span className="text-lg font-medium">
                    <span className="text-lg font-medium">
                      {metalLivePriceLoading ? (
                        <Skeleton width={100} animation="wave" />
                      ) : metalLivePrice?.data ? (
                        getRupeeWithDecimal.format(selectedAmount)
                      ) : (
                        '-'
                      )}
                    </span>
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center my-2">
            <Checkbox
              checked={isCheckboxSelected}
              onClick={() => setIsCheckboxSelected(!isCheckboxSelected)}
            />
            <span className="text-sm text-stone-700 opacity-60 leading-5 width-[20ch]">
              By proceeding you agree to our{' '}
              <a
                href="https://tapinvest.in/tnc"
                className="underline underline-offset-4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>{' '}
              involved.
            </span>
          </div>
          <div className="mt-6">
            <SelectClient
              client={client}
              setClient={setClient}
              eventName={GOLD_EVENTS.SELECT_CLIENT}
              showGold={true}
              refetchClients={fetchClients}
            />
          </div>
          {/* {!!client && client?.balance < parseInt(investmentAmount) &&
                        <p className="text-amber-700 mt-1.5 mb-2 text-sm font-semibold flex items-center gap-1"><InfoIcon fontSize="small" /> There's insufficient money in the client's wallet</p>
                    } */}
          <div className="mt-4 flex justify-between items-center gap-2.5">
            {/* {!!client && !isClientWalletEnough &&
                            <button
                                onClick={handleWalletOpen}
                                type="button"
                                className="bg-white shadow flex-shrink-0 flex items-center gap-2 text-sm text-gray-700 border border-gray-300 rounded-xl font-semibold h-10 w-fit px-4"
                            >
                                View Wallet Details
                            </button>
                        } */}
            {plan === 'SELL_GOLD' ? (
              <KYCButton
                text="Sell Gold"
                isLoading={isInvestmentLoading}
                className="!mt-0 h-10 !py-0 shadow"
                style={{
                  boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
                  background:
                    !clientId || !partnerId || !selectedAmount || !client || client.metalBlocked
                      ? '#E0E0E0'
                      : 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 119.05%), #D97706',
                  color:
                    !clientId || !partnerId || !selectedAmount || !client || client.metalBlocked
                      ? '#9E9E9E'
                      : '#FFFFFF',
                  opacity:
                    !clientId || !partnerId || !selectedAmount || !client || client.metalBlocked
                      ? 0.7
                      : 1,
                  cursor:
                    !clientId || !partnerId || !selectedAmount || !client || client.metalBlocked
                      ? 'not-allowed'
                      : 'pointer',
                }}
                disabled={
                  !clientId || !partnerId || !selectedAmount || !client || client.metalBlocked
                }
              />
            ) : (
              <KYCButton
                text="Generate Payment Link"
                isLoading={isInvestmentLoading}
                className="!mt-0 h-10 !py-0 shadow"
                style={{
                  boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
                  background:
                    !clientId ||
                    !partnerId ||
                    !isCheckboxSelected ||
                    !selectedAmount ||
                    !client ||
                    client.metalBlocked
                      ? '#E0E0E0'
                      : 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 119.05%), #D97706',
                  color:
                    !clientId ||
                    !partnerId ||
                    !isCheckboxSelected ||
                    !selectedAmount ||
                    !client ||
                    client.metalBlocked
                      ? '#9E9E9E'
                      : '#FFFFFF',
                  opacity:
                    !clientId ||
                    !partnerId ||
                    !isCheckboxSelected ||
                    !selectedAmount ||
                    !client ||
                    client.metalBlocked
                      ? 0.7
                      : 1,
                  cursor:
                    !clientId ||
                    !partnerId ||
                    !isCheckboxSelected ||
                    !selectedAmount ||
                    !client ||
                    client.metalBlocked
                      ? 'not-allowed'
                      : 'pointer',
                }}
                disabled={
                  !clientId ||
                  !partnerId ||
                  !isCheckboxSelected ||
                  !selectedAmount ||
                  !client ||
                  client.metalBlocked
                }
              />
            )}
          </div>
          <MessageCycler messages={messages} />
          {/* FAQs */}
          <div className="mt-6 flex flex-row gap-1 items-baseline">
            <h3 className="text-black/40 text-sm font-medium">Have a question?</h3>
            <a href="/faq" className="underline underline-offset-2 text-base text-stone-600 ">
              Checkout FAQ's
            </a>
          </div>
        </form>
      </div>

      {/* Client Generate Payment Link */}
      <Dialog open={isPaymentOpen} maxWidth="sm" fullWidth>
        <div className="flex bg-amber-100 p-2.5 items-center gap-2 ">
          <InfoIcon
            className="text-amber-700"
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <h4 className="text-sm text-amber-700 font-medium">
            Price will be changed in the next 5 minutes
          </h4>
        </div>
        <DialogTitle>
          <div className="px-5 flex justify-between">
            <div>
              <h3 className="text-lg font-semibold">Link Generated</h3>
              <p className="text-slate-600 text-sm mt-1.5">
                Payment link is valid for 6 hours, however price will change every 5 minutes.
              </p>
            </div>
            <CloseOutlinedIcon
              className="cursor-pointer bg-slate-200 rounded-full p-1"
              onClick={() => handlePaymentClose()}
              style={{
                width: '28px',
                height: '28px',
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="py-5bg-white rounded-lg">
            <div className="p-5 pb-3 flex gap-3">
              <div
                className="flex flex-col justify-between gap-[6px]  flex-1"
                style={{
                  padding: '14px 16px 16px 0px',
                  borderRight: '1px solid #f0f0f0',
                }}
              >
                <h4 className="text-[10px] text-stone-400 ">INVESTMENT FOR</h4>
                <h3 className="text-sm font-medium">{client?.name || '-'}</h3>
              </div>
              <div
                className="flex flex-col justify-between gap-[6px] flex-1"
                style={{
                  padding: '14px 16px 16px 0px',
                  borderRight: '1px solid #f0f0f0',
                }}
              >
                <h4 className="text-[10px] text-stone-400">INVESTMENT IN RUPPEE</h4>
                <h3 className="text-sm font-medium">{getRupeeWithDecimal.format(gstAmount)}</h3>
              </div>
              <div
                className="flex flex-col justify-between gap-[6px] flex-1"
                style={{ padding: '14px 16px 16px 24px' }}
              >
                <h4 className="text-[10px] text-stone-400">INVESTMENT IN GRAMS</h4>
                <h3 className="text-sm font-medium">
                  {fourDecimalNumberWithoutRounding(selectedGram) + 'gms'}
                </h3>
              </div>
            </div>
            <div className="px-5 pt-6 pb-3">
              <div className="flex flex-col gap-[6px]">
                <h4 className="text-sm text-gray-600">Share link with your client</h4>
                <div className="flex items-center w-full">
                  <input
                    type="text"
                    value={`${userPaymentLink || 'loading link...'}`}
                    readOnly
                    className="flex-grow border border-gray-300 rounded-l-xl p-2"
                  />
                  <button
                    // onClick={copyToClipboard}
                    onClick={() => {
                      navigator.clipboard.writeText(userPaymentLink);
                      toast.success('Link copied to clipboard', {
                        duration: 2000,
                        position: 'top-center',
                      });
                    }}
                    className="bg-green-700 text-white text-sm font-semibold h-[2.65rem] px-4 rounded-r-xl "
                  >
                    Copy Url
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ClientDrawer />
    </div>
  );
};

export default DigitalGold;
