import * as React from 'react';
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { baseColors } from '../../assets/theme';
import { useSubPartnerInvestments } from './usePartners';
import { getRowsAndCols } from './utils';
import { PARTNER_SORTABLE_COLUMNS } from './constants';

const investmentColumns = [
  {
    id: 'companyName',
    label: 'Deal Name',
    minWidth: 170,
    maxWidth: 180,
    align: 'left',
  },
  {
    id: 'subPartnerName',
    label: 'Partner Name',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'clientName',
    label: 'Client Name',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'amount',
    label: 'Invested Amount',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'investedOn',
    label: 'Invested on',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'earnedIncome',
    label: 'Sub-Partner Earnings',
    minWidth: 100,
    align: 'left',
  },
];

interface PartnersInvestmentTableProps {
  data?: {
    paginationDTO: any;
    currentPage: number;
    items: any[];
    pageSize: number;
    totalItems: number;
  };
  pageSize: number;
  pageNo: number;
  orderBy: string;
  order: 'asc' | 'desc';
  type?: string;
  search?: string;
  handleChangePage: (event: any, newPage: number) => void;
  handleChangeRowsPerPage: (event: any) => void;
  handleRowClick: (row: any) => void;
  handleChangeSort: (event: any, columnId: string) => void;
  setPartnersInvestmentTableData?: any;
}

const PartnersInvestmentTable: React.FC<PartnersInvestmentTableProps> = ({
  pageSize,
  pageNo,
  orderBy,
  order,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRowClick,
  handleChangeSort,
  type = 'subPartnerInvestments',
  search,
  setPartnersInvestmentTableData,
}) => {
  const { data, isLoading } = useSubPartnerInvestments({
    pageNo,
    pageSize,
    orderBy,
    order,
    search,
  });

  React.useEffect(() => {
    setPartnersInvestmentTableData && setPartnersInvestmentTableData(data?.data?.items || []);
  }, [data, setPartnersInvestmentTableData]);

  const row = getRowsAndCols(data?.data, type);

  const totalData = data?.paginationDTO?.totalElements || 0;

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        borderRadius: '10px',
        boxShadow: 'none',
        marginBottom: '20px',
        border: `1px solid ${baseColors.slate[300]}`,
      }}
    >
      <TableContainer
        sx={{
          borderRadius: '10px',
        }}
      >
        {isLoading ? (
          <Table stickyHeader aria-label="loading table">
            <TableHead>
              <TableRow>
                {investmentColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      backgroundColor: '#f6f6f2',
                    }}
                  >
                    <Skeleton variant="rectangular" width="100%" height={20} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(new Array(8)).map((_, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: '#FBFBF6',
                  }}
                >
                  {investmentColumns.map((_, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton variant="rectangular" width="100%" height={20} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : data?.elements ? (
          <div className="flex justify-center items-center py-10">
            <Typography>No Data Available</Typography>
          </div>
        ) : (
          <Table stickyHeader aria-label="sub-partner investments table">
            <TableHead>
              <TableRow>
                {investmentColumns.map((column) => {
                  const columnId = PARTNER_SORTABLE_COLUMNS?.[column.id];

                  return (
                    <TableCell
                      key={column.id}
                      sx={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        backgroundColor: '#f6f6f2',
                      }}
                      sortDirection={orderBy === columnId ? order : false}
                    >
                      {columnId ? (
                        <TableSortLabel
                          active={orderBy === columnId}
                          direction={orderBy === columnId ? order : 'asc'}
                          onClick={(e) => handleChangeSort && handleChangeSort(e, columnId)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {row.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  sx={{
                    backgroundColor: row?.name?.kyc === false ? '#FEF2F2' : '#FBFBF6',
                  }}
                  onClick={() => handleRowClick(row)}
                >
                  {investmentColumns.map((column) => {
                    let value = row[column.id];
                    let secondaryValue =
                      column.id === 'subPartnerName' ? row['subPartnerEmail'] : null;
                    let displayValue;
                    if (
                      column.id === 'dealName' &&
                      typeof value === 'object' &&
                      value?.props?.name?.toLowerCase() === 'p2p'
                    ) {
                      // Create a new object with modified properties instead of altering the original
                      displayValue = {
                        ...value,
                        props: {
                          ...value.props,
                          name: 'Tap Reserve',
                        },
                      };
                    } else {
                      displayValue = value;
                    }

                    return (
                      <TableCell
                        key={column.id}
                        className={secondaryValue ? '!flex !flex-col !items-start' : ''}
                      >
                        {typeof displayValue === 'object'
                          ? displayValue?.value || displayValue
                          : displayValue}
                        {secondaryValue && (
                          <Typography variant="caption">{secondaryValue}</Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {!isLoading && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={totalData}
          rowsPerPage={pageSize}
          page={pageNo}
          sx={{
            backgroundColor: '#f6f6f2',
            '.MuiPaginationItem-root': {
              backgroundColor: '#f6f6f2',
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

PartnersInvestmentTable.displayName = 'PartnerTable';

export default PartnersInvestmentTable;
